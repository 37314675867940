import { CSSProperties, FC } from 'react';
import { RichTextBlock } from 'prismic-reactjs';

import { BackgroundColor } from '@lib/slice-machine/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Image } from '@lib/slice-machine/types';
import { Box } from '@mui/system';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
interface Props {
  title: RichTextBlock[];
  subtitle: RichTextBlock[];
  backgroundColor: BackgroundColor;
  image?: Image;
  textAlign?: CSSProperties['textAlign'];
  alignItems?: CSSProperties['alignItems'];
  direction?: FlexDirection;
}

export const Tile: FC<Props> = ({
  title,
  subtitle,
  backgroundColor,
  image,
  textAlign = 'center',
  alignItems = 'center',
  direction = 'row',
}) => {
  const styles = getStyles(textAlign, alignItems, direction);
  return (
    <Box bgcolor={backgroundColor} p={2} sx={styles.getValue('contentWrapper')}>
      {image?.url && (
        <Box>
          <DynamicImage
            src={image.url}
            alt={image.alt ?? ''}
            width={100}
            height={100}
            objectFit="contain"
          />
        </Box>
      )}
      <Box sx={styles.getValue('textWrapper')}>
        <CustomRichText render={title} containerSx={styles.getValue('title')} />
        <CustomRichText
          render={subtitle}
          containerSx={styles.getValue('subtitle')}
        />
      </Box>
    </Box>
  );
};

const getStyles = (
  textAlign: CSSProperties['textAlign'],
  alignItems: CSSProperties['alignItems'],
  direction: FlexDirection,
) =>
  new SxStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: direction,
      gap: 2,
      justifyContent: 'left',
      alignItems,
      height: '100%',
      width: '100%',
    },
    textWrapper: {
      width: '100%',
    },
    title: {
      textAlign,
    },
    subtitle: {
      textAlign,
    },
  });
